/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.container { margin: 1%; }
.form-control{
  margin: 5px !important;

}
.form-select{
  margin: 5px !important;

}

.input-date{
  margin-right: 0px !important;
}
.input-group-append{
  margin: 5px !important;
  margin-left: 0px!important;
}
.mat-button-base {
  margin: 8px 8px 8px 8px !important;
}


.mat-card-subtitle, .mat-card-content {
  display: block;
  margin-bottom: 10px!important;
}

.green {
  color: green;
  font-weight: bold;
}
.red{
  color: red;
  font-weight: bold;
}
/* Absolute Center Spinner */
/*noinspection ALL*/
.loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
div {
  white-space: pre-line;
}
